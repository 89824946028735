export const interface_translate = {
  'This page is not available to you': {
    ru: 'Вам недоступна эта страница',
    en: 'This page is not available to you',
    ch: '您无法进入该页面',
  },
  'Not found': { ru: 'Не найдено', en: 'Not found', ch: '未找到' },
  id: { ru: 'id', en: 'id', ch: 'id' },
  rub: { ru: 'руб', en: 'rub', ch: 'rub' },
  usd: { ru: 'usd', en: 'usd', ch: 'usd' },
  'product id': { ru: 'id товара', en: 'product id', ch: 'ID 产品' },
  'counterparty id': { ru: 'id контрагента', en: 'counterparty id', ch: 'ID 交易对手' },
  'customer cargo id': {
    ru: 'id клиентского груза',
    en: 'customer cargo id',
    ch: 'ID 客户的货物',
  },
  'order id': { ru: 'id заказа', en: 'order id', ch: 'ID 命令' },
  'delivery id': { ru: 'id поставки', en: 'delivery id', ch: '递送 ID' },
  'product id': { ru: 'id товара', en: 'product id', ch: 'ID 产品' },
  'shelving id': { ru: 'id стеллажа', en: 'shelving id', ch: 'ID 架子' },
  'shipment id': { ru: 'id отправки', en: 'shipment id', ch: 'ID 发送' },
  'storage cell id': {
    ru: 'id ячейки хранения',
    en: 'storage cell id',
    ch: 'ID 存储单元',
  },
  'storehouse id': { ru: 'id склада', en: 'storehouse id', ch: 'ID 仓库' },
  'unidentified cargo id': {
    ru: 'id неопознанного груза',
    en: 'unidentified cargo id',
    ch: 'ID 不明货物',
  },
  'unidentified delivery id': {
    ru: 'id неопознанной посылки',
    en: 'unidentified delivery id',
    ch: '未知的货物 ID',
  },
  Filter: { ru: 'Фильтр', en: 'Filter', ch: '筛选' },
  Delete: { ru: 'Удалить', en: 'Delete', ch: '删除' },
  'Delete?': { ru: 'Удалить?', en: 'Delete?', ch: '删除?' },
  Create: { ru: 'Создать', en: 'Create', ch: '新建' },
  Edit: { ru: 'Редактировать', en: 'Edit', ch: '编辑' },
  Update: { ru: 'Обновить', en: 'Update', ch: '更新' },
  Save: { ru: 'Сохранить', en: 'Save', ch: '保持' },
  Back: { ru: 'Назад', en: 'Back', ch: '返回' },
  Exit: { ru: 'Выйти', en: 'Exit', ch: '出退' },
  Add: { ru: 'Добавить', en: 'Add', ch: '添加' },
  'Assign storage cell': {
    ru: 'Назначить ячейку хранения',
    en: 'assign a storage cell',
    ch: '指定存储单元',
  },
  Encontrar: { ru: 'Найти', en: 'Encontrar', ch: '搜索' },
  Borrar: { ru: 'Очистить', en: 'Borrar', ch: '清除' },
  Video: { ru: 'Видео', en: 'Video', ch: '视频' },
  Download: { ru: 'Скачать', en: 'Download', ch: '下载' },
  yes: { ru: 'да', en: 'yes', ch: '是' },
  Yes: { ru: 'Да', en: 'Yes', ch: '是' },
  no: { ru: 'нет', en: 'no', ch: '无' },
  No: { ru: 'Нет', en: 'No', ch: '无' },
  Confirm: { ru: 'Подтвердить', en: 'Confirm', ch: '确认' },
  'Data added': { ru: 'Данные добавлены', en: 'Data added', ch: '新增资料' },
  'Qty entries': { ru: 'Кол-во записей', en: 'Qty entries', ch: '记录数目' },
  responsible: { ru: 'ответственные', en: 'responsible', ch: '负责人' },
  Responsible: { ru: 'Ответственные', en: 'Responsible', ch: '负责人' },
  'Co executors': { ru: 'Соисполнители', en: 'Co executors', ch: '共同执行者' },
  Counterparties: { ru: 'Контрагенты', en: 'Counterparties', ch: '承办商' },
  'About сounterparty': {
    ru: 'О контрагенте',
    en: 'About counterpaty',
    ch: '交易对手',
  },
  'Counterpaty created': {
    ru: 'Контрагент создан',
    en: 'Counterpaty created',
    ch: '交易对手已创建',
  },
  'Counterpaty updated': {
    ru: 'Контрагент обновлен',
    en: 'Counterpaty updated',
    ch: '交易对手已公布',
  },
  name: { ru: 'наименование', en: 'name', ch: '名称' },
  Name: { ru: 'Наименование', en: 'Name', ch: '名称' },
  company: { ru: 'компания', en: 'company', ch: '公司' },
  Company: { ru: 'Компания', en: 'Company', ch: '公司' },
  group: { ru: 'группа', en: 'group', ch: '类型' },
  Group: { ru: 'Группа', en: 'Group', ch: '类型' },
  'phone number': { ru: 'номер телефона', en: 'phone number', ch: '电话号码' },
  'Phone number': { ru: 'Номер телефона', en: 'Phone number', ch: '电话号码' },
  'contact person': {
    ru: 'контактное лицо',
    en: 'contact person',
    ch: '联络人',
  },
  'Contact person': {
    ru: 'Контактное лицо',
    en: 'Contact person',
    ch: '联络人',
  },
  'link to messenger': {
    ru: 'ссылка на соц. сеть',
    en: 'link to messenger',
    ch: '社交媒体链接',
  },
  Orders: { ru: 'Заказы', en: 'Orders', ch: '订货单' },
  'About order': { ru: 'О заказе', en: 'About order', ch: '关于订单' },
  'Order created': {
    ru: 'Заказ создан',
    en: 'Order created',
    ch: '订单已创建',
  },
  'Order updated': {
    ru: 'Заказ обновлен',
    en: 'Order updated',
    ch: '订单已更新',
  },
  'Add supplier': { ru: 'Добавить поставщика', en: 'Add supplier', ch: '添加供应商' },
  'Rate cny to rub': {
    ru: 'Курс юаня к рублю',
    en: 'Rate cny to rub',
    ch: '人民币对卢布汇率',
  },
  'Rate usd to rub': {
    ru: 'Курс доллара к рублю',
    en: 'Rate dollar to rub',
    ch: '美元对卢布汇率',
  },
  'Total amount ₽': {
    ru: 'Общая сумма ₽',
    en: 'Total amount ₽',
    ch: '总金额卢布',
  },
  'Create delivery': {
    ru: 'Создать поставку',
    en: 'Create delivery',
    ch: '创建交货',
  },
  'datetime created': {
    ru: 'дата и время создания',
    en: 'datetime created',
    ch: '创建日期和时间',
  },
  'Datetime created': {
    ru: 'Дата и время создания',
    en: 'Datetime created',
    ch: '创建日期和时间',
  },
  client: { ru: 'клиент', en: 'client', ch: '客户' },
  clients: { ru: 'клиенты', en: 'client', ch: '客户' },
  Client: { ru: 'Клиент', en: 'Client', ch: '客户' },
  'estimated cost logistics': {
    ru: 'расчетная стоимость логистики в $',
    en: 'estimated cost logistics in $',
    ch: '预计物流成本 $',
  },
  'Estimated cost logistics': {
    ru: 'Расчетная стоимость логистики в $',
    en: 'Estimated cost logistics in $',
    ch: '预计物流成本 $',
  },
  'final cost logistics': {
    ru: 'итоговая стоимость логистики в ₽',
    en: 'final cost logistics in ₽',
    ch: '最终物流成本 ₽',
  },
  'Final cost logistics': {
    ru: 'Итоговая стоимость логистики в ₽',
    en: 'Final cost logistics in ₽',
    ch: '最终物流成本 ₽',
  },
  completed: { ru: 'завершен', en: 'completed', ch: '已完成' },
  Completed: { ru: 'Завершен', en: 'completed', ch: '已完成' },
  Products: { ru: 'Товары', en: 'Products', ch: '产品' },
  'Received products': {
    ru: 'Поступившие товары',
    en: 'Received products',
    ch: '已收到的商品',
  },
  'About product': { ru: 'О товаре', en: 'About product', ch: '关于产品' },
  'Product created': {
    ru: 'Товар создан',
    en: 'Product created',
    ch: '产品已创建',
  },
  'Product updated': {
    ru: 'Товар обновлен',
    en: 'Product created',
    ch: '产品已更新',
  },
  'Check the delivery': {
    ru: 'Проверка поставки',
    en: 'Check the delivery',
    ch: '交货支票',
  },
  'Check the customer cargo': {
    ru: 'Проверка клиентского груза',
    en: 'Check the customer cargo',
    ch: '检查客户货物',
  },
  description: { ru: 'описание', en: 'description', ch: '描述' },
  Description: { ru: 'Описание', en: 'Description', ch: '描述' },
  'total cost $': { ru: 'общая стоимость $', en: 'total cost $', ch: '总成本 $' },
  'total cost': { ru: 'общая стоимость', en: 'total cost', ch: '总成本' },
  'Qty ordered products': {
    ru: 'Количество заказанного товаров',
    en: 'Qty ordered_products',
    ch: '订购商品数量',
  },
  'Qty products in china': {
    ru: 'Количество товаров в Китае',
    en: 'Qty products in china',
    ch: '中国产品数量',
  },
  'Qty shipped products': {
    ru: 'Количество отправленных товаров',
    en: 'Qty shipped products',
    ch: '运送的物品数量',
  },
  'Qty products in russia': {
    ru: 'Количество товаров в России',
    en: 'Qty products in russia',
    ch: '俄罗斯的产品数量',
  },
  'Qty issued roducts': {
    ru: 'Количество выданного товара',
    en: 'Qty issued products',
    ch: '发出货物数量',
  },
  'qty ordered products': {
    ru: 'количество заказанного товаров',
    en: 'qty ordered_products',
    ch: '订购的商品数量',
  },
  'qty products in china': {
    ru: 'количество товаров в Китае',
    en: 'qty products in china',
    ch: '中国产品数量',
  },
  'qty shipped products': {
    ru: 'количество отправленных товаров',
    en: 'qty shipped products',
    ch: '运送的物品数量',
  },
  'qty products in russia': {
    ru: 'количество товаров в России',
    en: 'qty products in russia',
    ch: '俄罗斯的产品数量',
  },
  'qty issued products': {
    ru: 'количество выданного товара',
    en: 'qty issued products',
    ch: '发出货物数量',
  },
  'Qty ordered products': {
    ru: 'Количество заказанного товаров',
    en: 'Qty ordered_products',
    ch: '订购的商品数量',
  },
  'Qty products in china': {
    ru: 'Количество товаров в Китае',
    en: 'Qty products in china',
    ch: '中国产品数量',
  },
  'Qty shipped products': {
    ru: 'Количество отправленных товаров',
    en: 'Qty shipped products',
    ch: '运送的物品数量',
  },
  'Qty products in russia': {
    ru: 'Количество товаров в России',
    en: 'Qty products in russia',
    ch: '俄罗斯的产品数量',
  },
  'Qty issued roducts': {
    ru: 'Количество выданного товара',
    en: 'Qty issued products',
    ch: '发出货物数量',
  },
  'qty ordered products': {
    ru: 'количество заказанного товаров',
    en: 'qty ordered_products',
    ch: '订购的商品数量',
  },
  'qty products in china': {
    ru: 'количество товаров в Китае',
    en: 'qty products in china',
    ch: '中国产品数量',
  },
  'qty shipped products': {
    ru: 'количество отправленных товаров',
    en: 'qty shipped products',
    ch: '运送的物品数量',
  },
  'qty products in russia': {
    ru: 'количество товаров в России',
    en: 'qty products in russia',
    ch: '俄罗斯的产品数量',
  },
  'qty issued roducts': {
    ru: 'количество выданного товара',
    en: 'qty issued products',
    ch: '发出货物数量',
  },
  link: { ru: 'ссылка', en: 'link', ch: '连接' },
  Link: { ru: 'Ссылка', en: 'Link', ch: '连接' },
  qty: { ru: 'кол-во', en: 'qty', ch: '数目' },
  Qty: { ru: 'Кол-во', en: 'Qty', ch: '数目' },
  price: { ru: 'цена', en: 'price', ch: '价格' },
  Price: { ru: 'Цена', en: 'Price', ch: '价格' },
  currency: { ru: 'валюта', en: 'currency', ch: '货币' },
  Currency: { ru: 'Валюта', en: 'Currency', ch: '货币' },
  supplier: { ru: 'поставщик', en: 'supplier', ch: '供应商' },
  Supplier: { ru: 'Поставщик', en: 'Supplier', ch: '供应商' },
  media: { ru: 'медиа', en: 'media', ch: '媒体内容' },
  Media: { ru: 'Медиа', en: 'Media', ch: '媒体内容' },
  'Media content': { ru: 'Медиа контент', en: 'Media content', ch: '媒体内容' },
  'media content': { ru: 'медиа контент', en: 'media content', ch: '媒体内容' },
  'Allowed formats': {
    ru: 'Разрешенные форматы',
    en: 'Allowed formats',
    ch: '允许的格式',
  },
  Deliveries: { ru: 'Поставки', en: 'Deliveries', ch: '递送' },
  'About delivery': { ru: 'О поставке', en: 'About delivery', ch: '关于递送' },
  'Delivery create': {
    ru: 'Поставка создана',
    en: 'Delivery create',
    en: 'Delivery create',
    ch: '交货已创建',
  },
  'Delivery updated': {
    ru: 'Поставка обновлена',
    en: 'Delivery updated',
    en: 'Delivery updated',
    ch: '交付已更新',
  },
  'Enter the track number': {
    ru: 'Ввести трек номер',
    en: 'Enter the track number',
    ch: '输入轨道编号',
  },
  'Create an unidentified delivery': {
    ru: 'Создать неопознанную посылку',
    en: 'Create an unidentified delivery',
    ch: '创建身份不明包裹',
  },
  'Scan track number': {
    ru: 'Сканировать трек номер',
    en: 'Scan track number',
    ch: '物流单号',
  },
  'Coordination with the client': {
    ru: 'Согласование с клиентом',
    en: 'Coordination with the client',
    ch: '与客户协议',
  },
  'Refund to supplier': {
    ru: 'Возврат поставищику',
    en: 'Refund to supplier',
    ch: '退款供应商',
  },
  'For products selected storage cells': {
    ru: 'Выбрать ячейки хранения',
    en: 'Selected storage cells',
    ch: '选择存储单元',
  },
  'Request refund to supplier?': {
    ru: 'Запросить возврат поставщику?',
    en: 'Request a refund to the supplier?',
    ch: '要求退款供应商？',
  },
  'Was check qty': {
    ru: 'Была проверка кол-ва',
    en: 'Was check qty',
    ch: '数量已核对',
  },
  'qty defective products': {
    ru: 'кол-во бракованного товара',
    en: 'qty defective products',
    ch: '不良品数量',
  },
  'Was check qty': {
    ru: 'Была проверка кол-ва',
    en: 'Was check qty',
    ch: '数量已核对',
  },
  'qty defective products': {
    ru: 'кол-во бракованного товара',
    en: 'qty defective products',
    ch: '不良品数量',
  },
  order: { ru: 'заказ', en: 'order', ch: '订单' },
  Order: { ru: 'Заказ', en: 'Order', ch: '订单' },
  product: { ru: 'товар', en: 'product', ch: '产品' },
  Product: { ru: 'Товар', en: 'Product', ch: '产品' },
  'track number': { ru: 'трек номер', en: 'track number', ch: '轨道号' },
  'Track number': { ru: 'Трек номер', en: 'Track number', ch: '轨道号' },
  status: { ru: 'статус', en: 'status', ch: '动态' },
  Status: { ru: 'Статус', en: 'Status', ch: '动态' },
  shipment: { ru: 'отправление', en: 'shipment', ch: '出发' },
  Shipment: { ru: 'Отправление', en: 'Shipment', ch: '出发' },
  'total weight in kg': {
    ru: 'общий вес в кг',
    en: 'total weight in kg',
    ch: '总重量（公斤',
  },
  'Total weight in kg': {
    ru: 'Общий вес в кг',
    en: 'Total weight in kg',
    ch: '总重量（公斤',
  },
  'total volume in м³': {
    ru: 'общий объем в м³',
    en: 'total volume in м³',
    ch: '总体积（立方米',
  },
  'Total volume in м³': {
    ru: 'Общий объем в м³',
    en: 'Total volume in м³',
    ch: '总体积（立方米）',
  },
  'actual content': {
    ru: 'фактическое содержимое',
    en: 'actual content',
    ch: '实际内容',
  },
  'Actual content': {
    ru: 'Фактическое содержимое',
    en: 'Actual content',
    ch: '实际内容',
  },
  'The sending data has been added': {
    ru: 'Данные отправки добавлены',
    en: 'The sending data has been added',
    ch: '添加发送数据',
  },
  storehouse: { ru: 'склад', en: 'storehouse', ch: '仓库' },
  Storehouse: { ru: 'Склад', en: 'Storehouse', ch: '仓库' },
  'storage cell': {
    ru: 'ячейка хранения',
    en: 'storage cell',
    ch: '存储单元',
  },
  'Storage cell': {
    ru: 'Ячейка хранения',
    en: 'Storage cell',
    ch: '存储单元',
  },
  'add information': {
    ru: 'добавить информацию',
    en: 'add information',
    ch: '添加信息',
  },
  'Add information': {
    ru: 'Добавить информацию',
    en: 'Add information',
    ch: '添加信息',
  },
  'Qr code': { ru: 'Qr код', en: 'QR code', ch: '二维码' },
  'Media content of acceptance': {
    ru: 'Медиа-контент приемки',
    en: 'Media content of the acceptance',
    ch: '媒体内容',
  },
  'Unidentified deliveries': {
    ru: 'Неопознанные посылки',
    en: 'Unidentified deliveries',
    ch: '身份不明包裹',
  },
  'About unidentified delivery': {
    ru: 'О неопознанной посылке',
    en: 'About unidentified delivery',
    ch: '关于身份不明包裹',
  },
  'Unidentified delivery created': {
    ru: 'Неопознанная посылка создана',
    en: 'Unidentified delivery created',
    ch: '已创身份不明包裹',
  },
  'Unidentified delivery updated': {
    ru: 'Неопознанная посылка обновлена',
    en: 'Unidentified delivery updated',
    ch: '已创身份不明包裹更新',
  },
  'weight in kg': { ru: 'вес в кг', en: 'weight in kg', ch: '重量（公斤' },
  'Weight in kg': { ru: 'Вес в кг', en: 'Weight in kg', ch: '重量（公斤' },
  'volume in м³': { ru: 'объем в м³', en: 'volume in м³', ch: '体积（立方米）' },
  'Volume in м³': { ru: 'Объем в м³', en: 'Volume in м³', ch: '体积（立方米）' },
  Shipments: { ru: 'Отправления', en: 'Shipments', ch: '发货' },
  'About shipment': {
    ru: 'Об отправлении',
    en: 'About shipment',
    ch: '关于发货',
  },
  'Shipment created': {
    ru: 'Отправление создано',
    en: 'Shipment created',
    ch: '出发创建',
  },
  'Shipment updated': {
    ru: 'Отправление обновлено',
    en: 'Shipment updated',
    ch: '出发更新',
  },
  'Ready to ship': {
    ru: 'Готово к отправке',
    en: 'Ready to ship',
    ch: '准备装运',
  },
  'type logistics': {
    ru: 'тип логистики',
    en: 'type logistics',
    ch: '物流类型',
  },
  'Type logistics': {
    ru: 'Тип логистики',
    en: 'Type logistics',
    ch: '物流类型',
  },
  'Number of seats': { ru: 'Кол-во мест', en: 'Number of seats', ch: '座位数' },
  'number of seats': { ru: 'кол-во мест', en: 'number of seats', ch: '座位数' },
  packing: { ru: 'упаковка', en: 'packing', ch: '包装' },
  Packing: { ru: 'Упаковка', en: 'Packing', ch: '包装' },
  delivery: { ru: 'поставка', en: 'delivery', ch: '供应' },
  Delivery: { ru: 'Поставка', en: 'Delivery', ch: '供应' },
  white: { ru: 'белая', en: 'white', ch: '白的' },
  White: { ru: 'Белая', en: 'White', ch: '白的' },
  'cargo number': { ru: 'номер накладной', en: 'cargo number', ch: '送货单号' },
  cargo: { ru: 'карго', en: 'cargo', ch: '物流' },
  Cargo: { ru: 'Карго', en: 'Cargo', ch: '物流' },
  'Documents logístics': {
    ru: 'Документы логистики',
    en: 'Documents logístics',
    ch: '物流文件',
  },
  Document: { ru: 'Документ', en: 'Document', ch: '文件' },
  'White delivery': {
    ru: 'Белая доставка',
    en: 'White delivery',
    ch: '白物流',
  },
  'Cargo delivery': {
    ru: 'Карго доставка',
    en: 'Cargo delivery',
    ch: '物流送货',
  },
  'Type of transport': {
    ru: 'Тип транспорта',
    en: 'Type of transport',
    ch: '运输类别',
  },
  'Departure date': {
    ru: 'Дата выезда',
    en: 'Departure date',
    ch: '出发日期',
  },
  'Estimated date of arrival in Moscow': {
    ru: 'Рассчетная дата прибытия в москву',
    en: 'Estimated date of arrival in Moscow',
    ch: '预计抵达日期到莫斯科',
  },
  'Total shipping cost': {
    ru: 'Общая стоимость доставки в $',
    en: 'Total shipping cost in ',
    ch: '总运费（美元）',
  },
  Rate: { ru: 'Ставка', en: 'Rate', ch: '金额' },
  'The cost of insurance': {
    ru: 'Стоимость страховки в $',
    en: 'The cost of insurance in $',
    ch: '保险费用（美元',
  },
  'The cost of packaging': {
    ru: 'Стоимость упаковки в $',
    en: 'Cost of packaging in $',
    ch: '包装成本（美元）',
  },
  'Actual total weight in kg': {
    ru: 'Фактический общий вес в кг',
    en: 'Actual total weight in kg',
    ch: '实际总重量（公斤）',
  },
  'Actual total volume in м³': {
    ru: 'Фактический общий объем в м³',
    en: 'Actual total volume in м³',
    ch: '实际总容量',
  },
  'Invoice number': {
    ru: 'Номер накладной',
    en: 'Invoice number',
    ch: '送货单号',
  },
  'invoice number': {
    ru: 'номер накладной',
    en: 'invoice number',
    ch: '送货单号',
  },
  'The cost of delivery': {
    ru: 'Cтоимость доставки',
    en: 'The cost of delivery',
    ch: '交货成本',
  },
  'The cost of the FCS': {
    ru: 'Стоимость ФТС в $',
    en: 'The cost of the FCS in $',
    ch: 'FCS 成本（美元）',
  },
  'Customer cargo list': {
    ru: 'Клиентские грузы',
    en: 'Customer cargo',
    ch: '客户货物',
  },
  'Customer cargo': {
    ru: 'Клиентский груз',
    en: 'Customer cargo',
    ch: '客户货物',
  },
  'About customer cargo': {
    ru: 'О клиентском грузе',
    en: 'About customer cargo',
    ch: '关于客户货物',
  },
  'Customer cargo created': {
    ru: 'Клиентский груз создан',
    en: 'Customer cargo created',
    ch: '客户货物已创建',
  },
  'Customer cargo updated': {
    ru: 'Клиентский груз обновлен',
    en: 'Customer cargo updated',
    ch: '客户货物更新',
  },
  'Enter the invoce number': {
    ru: 'Ввести номер накладной',
    en: 'Enter the invoce number',
    ch: '输入发票号码',
  },
  'Ready to issue': {
    ru: 'Готово к выдаче',
    en: 'Ready to issue',
    ch: '准备出发',
  },
  content: { ru: 'содержимое', en: 'content', ch: '内容' },
  Content: { ru: 'Содержимое', en: 'Content', ch: '内容' },
  'Issue to the client': {
    ru: 'Выдать клиенту',
    en: 'Issue to the client',
    ch: '交给客户',
  },
  'Submit the closing documents': {
    ru: 'Внести закрывающие документы',
    en: 'Submit the closing documents',
    ch: '提交闭幕文件',
  },
  'Invoice documents': {
    ru: 'Документы накладной',
    en: 'Invoice documents',
    ch: '发票文件',
  },
  'Unidentified cargo list': {
    ru: 'Неопознанные грузы',
    en: 'Unidentified cargo',
    ch: '不明货',
  },
  'About unidentified cargo': {
    ru: 'Об неопознанном грузе',
    en: 'About unidentified cargo',
    ch: '关于不明货',
  },
  'Create unidentified cargo': {
    ru: 'Создать неопознанный груз',
    en: 'Create unidentified cargo',
    ch: '创建身份不明的货物',
  },
  'Unidentified cargo created': {
    ru: 'Неопознанный груз создан',
    en: 'Unidentified cargo created',
    ch: '已创建身份不明货物',
  },
  'Unidentified cargo updated': {
    ru: 'Неопознанный груз обновлен',
    en: 'Unidentified cargo updated',
    ch: '身份不明货物更新',
  },
  comment: { ru: 'комментарий', en: 'comment', ch: '信息' },
  Comment: { ru: 'Комментарий', en: 'Comment', ch: '信息' },
  Storehouses: { ru: 'Склады', en: 'Storehouses', ch: '仓库' },
  'About storehouse': {
    ru: 'О складе',
    en: 'About storehouse',
    ch: '关于仓库',
  },
  'Storehouse created': {
    ru: 'Склад создан',
    en: 'Storehouse created',
    ch: '仓库已创建',
  },
  'Storehouse updated': {
    ru: 'Склад обyновлен',
    en: 'Storehouse updated',
    ch: '仓库更新',
  },
  address: { ru: 'адрес', en: 'address', ch: '地址' },
  Address: { ru: 'Адрес', en: 'Address', ch: '地址' },
  Shelvings: { ru: 'Стеллажи', en: 'Shelvings', ch: '搁架' },
  'About shelving': {
    ru: 'О стеллаже',
    en: 'About shelving',
    ch: '关于搁架',
  },
  'Shelving created': {
    ru: 'Стеллаж создан',
    en: 'Shelving created',
    ch: '搁架已创建',
  },
  'Shelving updated': {
    ru: 'Стеллаж обновлен',
    en: 'Shelving updated',
    ch: '搁架更新',
  },
  free: { ru: 'свободно', en: 'free', ch: '空' },
  Free: { ru: 'Свободно', en: 'Free', ch: '空' },
  occupied: { ru: 'занято', en: 'occupied', ch: '被占用' },
  'Storage cells': {
    ru: 'Ячейки хранения',
    en: 'Storage cells',
    ch: '存储单元',
  },
  'About storage cell': {
    ru: 'Об ячейке хранения',
    en: 'About storage cell',
    ch: '关于存储单元',
  },
  'Storage cell created': {
    ru: 'Ячейка хранения создана',
    en: 'Storage cell created',
    ch: '已创建存储单元',
  },
  'Storage cell updated': {
    ru: 'Ячейка хранения обновлена',
    en: 'Storage cell updated',
    ch: '存储单元更新',
  },
  number: { ru: 'номер', en: 'number', ch: '存储单元号' },
  Number: { ru: 'Номер', en: 'Number', ch: '存储单元号' },
  shelving: { ru: 'стеллаж', en: 'shelving', ch: '搁架' },
  Shelving: { ru: 'Стеллаж', en: 'Shelving', ch: '搁架' },
  'Log in': { ru: 'Войти', en: 'Log in', ch: '进入' },
  Registration: { ru: 'Регистрация', en: 'Registration', ch: '登记注册' },
  Login: { ru: 'Логин', en: 'Login', ch: '登入' },
  Email: { ru: 'Почта', en: 'Email', ch: '邮件' },
  Password: { ru: 'Пароль', en: 'Password', ch: '密码' },
  'You are registered': {
    ru: 'Вы зарегистрированы',
    en: 'You are registered',
    ch: '您已注册',
  },
  Register: { ru: 'Зарегистрироваться', en: 'Register', ch: '登记册' },
  OK: { ru: 'OK', en: 'OK', ch: '确定' },
  Cancel: { ru: 'Отмена', en: 'Cancel', ch: '取消' },
  '': { ru: '', ch: '' },
  user: {
    ru: 'Пользователь',
    en: 'user',
    ch: '用户',
  },
  admin: {
    ru: 'Администратор',
    en: 'admin',
    ch: '管理员',
  },
  client_realtions_manager: {
    ru: 'Менеджер по работе с клиентами',
    en: 'client_realtions_manager',
    ch: '客户关系经理',
  },
  supplier_realtions_manager: {
    ru: 'Менеджер по работе с поставщиками',
    en: 'supplier_realtions_manager',
    ch: '供应商关系经理',
  },
  chinese_storekeeper_manager: {
    ru: 'Заведующий складом в Китае',
    en: 'chinese_storekeeper_manager',
    ch: '中国仓库主管',
  },
  chinese_storekeeper: {
    ru: 'Кладовщик в Китае',
    en: 'chinese_storekeeper',
    ch: '中国仓库管理员',
  },
  moscow_storekeeper_manager: {
    ru: 'Заведующий складом в Москве',
    en: 'moscow_storekeeper_manager',
    ch: '莫斯科仓库主管',
  },
  roman_assistant: {
    ru: 'Помощник Романа',
    en: 'roman_assistant',
    ch: '罗曼的助理',
  },
  'Role': {
    ru: 'Роль',
    en: 'Role',
    ch: '角色',
  },
  'Add Employee': {
    ru: 'Добавить сотрудника',
    en: 'Add Employee',
    ch: '添加员工',
  },
  'Employee added successfully': {
    ru: 'Сотрудник успешно добавлен',
    en: 'Employee added successfully',
    ch: '员工已成功添加',
  },
  'Unexpected error occurred': {
    ru: 'Произошла непредвиденная ошибка',
    en: 'Unexpected error occurred',
    ch: '发生意外错误',
  },
  'Full Name': {
    ru: 'ФИО',
    en: 'Full Name',
    ch: '全名',
  },
  'Phone Number': {
    ru: 'Номер телефона',
    en: 'Phone Number',
    ch: '电话号码',
  },
  'Please enter a valid full name': {
    ru: 'Пожалуйста, введите полное ФИО',
    en: 'Please enter a valid full name',
    ch: '请输入完整的姓名',
  },
  'Link to messenger': {
    ru: 'Ссылка на соц. сеть',
    en: 'Link to messenger',
    ch: '社交媒体链接',
  },
  'Full name must include surname, name, and patronymic': {
    ru: 'ФИО должно содержать фамилию, имя и отчество',
    en: 'Full name must include surname, name, and patronymic',
    ch: '全名必须包含姓氏、名字和父名',
  },
  'Bitrix ID': {
    ru: 'Битрикс ID',
    en: 'Bitrix ID',
    ch: 'Bitrix ID',
  },
  'Users': {
    ru: 'Пользователи',
    en: 'Users',
    ch: 'Users',
  },
  'Surname': { ru: 'Фамилия', en: 'Surname', ch: '姓' },
  'surname': { ru: 'фамилия', en: 'surname', ch: '姓' },
  'Patronymic': { ru: 'Отчество', en: 'Patronymic', ch: '父称' },
  'patronymic': { ru: 'отчество', en: 'patronymic', ch: '父称' },
  'Edit User': { ru: 'Редактирование пользователя', en: 'Edit User', ch: '编辑用户' },
  'email': { ru: 'почта', en: 'email', ch: '电子邮件' },
  'First Name': { ru: 'Имя', en: 'First Name', ch: '名' },
  'first_name': { ru: 'имя', en: 'first name', ch: '名' },
  'Employees': {
    en: 'Employees',
    ru: 'Сотрудники', 
    ch: '员工名单'
  },
  'terminated': { 
    ru: 'Уволен', 
    en: 'Terminated', 
    ch: '已终止'
  },
  'transport_type': { 
    en: 'Type of Transport', 
    ru: 'Тип транспорта', 
    ch: '运输类型' 
  },
  'departure_date': { 
    en: 'Departure Date', 
    ru: 'Дата выезда', 
    ch: '出发日期' 
  },
  'estimated_arrival_date_moscow': { 
    en: 'Estimated Arrival Date in Moscow', 
    ru: 'Расчетная дата прибытия в Москву', 
    ch: '预计抵达莫斯科日期' 
  },
  'total_shipping_cost_usd': { 
    en: 'Total Shipping Cost ($)', 
    ru: 'Общая стоимость доставки ($)', 
    ch: '总运费 ($)' 
  },
  'fcs_cost_usd': { 
    en: 'FCS Cost ($)', 
    ru: 'Стоимость ФТС ($)', 
    ch: '海关费用 ($)' 
  },
  'actual_total_weight_kg': { 
    en: 'Actual Total Weight (kg)', 
    ru: 'Фактический общий вес (кг)', 
    ch: '实际总重量 (公斤)' 
  },
  'actual_total_volume_m3': { 
    en: 'Actual Total Volume (m³)', 
    ru: 'Фактический общий объем (м³)', 
    ch: '实际总体积 (立方米)' 
  },
  'invoice_number': { 
    en: 'Invoice Number', 
    ru: 'Номер накладной', 
    ch: '发票号码' 
  },
  bulk_cargo_logistics: {
    ru: 'Карго доставка',
    en: 'Cargo Logistics',
  },
  bulk_white_logistics: {
    ru: 'Белая доставка',
    en: 'White Logistics',
  },
  'Common fields': {
    ru: 'Общие поля',
    en: 'Common fields',
    ch: '通用字段',
  },
  'Individual fields': {
    ru: 'Индивидуальные поля',
    en: 'Individual fields',
    ch: '个别字段',
  },
  'Shipment': {
    ru: 'Отправление',
    en: 'Shipment',
    ch: '发货',
  },
  'Please correct the form errors': {
    ru: 'Пожалуйста, исправьте ошибки в форме',
    en: 'Please correct the form errors',
    ch: '请更正表格中的错误',
  },
  'Add employee': {
    ru: 'Добавить сотрудника',
    en: 'Add employee',
    ch: '添加员工',
  },
  'Employee added successfully': {
    ru: 'Сотрудник успешно добавлен',
    en: 'Employee added successfully',
    ch: '员工添加成功',
  },
  'Email': {
    ru: 'Email',
    en: 'Email',
    ch: '电子邮件',
  },
  'Password': {
    ru: 'Пароль',
    en: 'Password',
    ch: '密码',
  },
  'Full name': {
    ru: 'ФИО',
    en: 'Full name',
    ch: '全名',
  },
  'Role': {
    ru: 'Роль',
    en: 'Role',
    ch: '角色',
  },
  'Link to messenger': {
    ru: 'Ссылка на мессенджер',
    en: 'Link to messenger',
    ch: '即时通讯链接',
  },
  'Confirm or reject products': {
    'ru': 'Пожалуйста, подтвердите или отклоните товары',
    'en': 'Please confirm or reject products',
    'zh': '请确认或拒绝产品'
  },
  'Enter track number manually': {
    'ru': 'Сканировать трек номер ТСД',
    'en': 'Please confirm or reject products',
    'zh': '请确认或拒绝产品'
  },
  'Enter track number': {
    'ru': 'Ввести трек номер',
    'en': 'Please confirm or reject products',
    'zh': '请确认或拒绝产品'
  }
};
