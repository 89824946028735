import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import shipmentService from '../../services/shipment';
import productService from '../../services/product';
import orderService from '../../services/order';
import userService from '../../services/user';
import styles from './ShipmentCreatePage.module.css';
import CoExecutorsInput from '../../components/input/CoExecutorsInput';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';
import '../shared.css';
import ProductsWithQtyInput from '../../components/input/ProductsWithQtyInput';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_packing() {
  const packing = await shipmentService.get_packing();
  return packing;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_orders(filters = {}, size = 50) {
  const ordersPaginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return ordersPaginated.data;
}

async function get_products(filters = {}, size = 50) {
  filters = {
    shipment_id: null,
    ...filters,
  };
  const productsPaginated = await productService.get_by_filters(filters, {
    size: size,
  });
  return productsPaginated.data;
}

const ShipmentCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [packing, setPacking] = useState([]);
  const [modelData, setModelData] = useState({});
  const [defUsers, setDefUsers] = useState([]);
  const [orderInputValue, setOrderInputValue] = useState(null);
  const [orderElementsForSelect, setOrderElementsForSelect] = useState([]);
  const [products, setProducts] = useState({});
  const [productsForSelect, setProductsForSelect] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const packingTypes = await get_packing();
      setPacking(packingTypes);
      setModelData({ ...modelData, packing: packingTypes[0] });

      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Shipments?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (orderInputValue) filters['id'] = orderInputValue;
      const new_elements = await get_orders(filters);
      setOrderElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderInputValue]);

  const getDataFromAttr = () => {
    if (modelData.order == null) {
      setErrors({
        order_id: ['There are blank fields left'],
      });
      return;
    }
    const shipment_products = Object.values(products).map((m) => {
      return { product_id: m.product.id, qty: m.qty };
    });
    for (let shipment_product of shipment_products) {
      if (!shipment_product.product_id || !shipment_product.qty) {
        setErrors({
          products_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      order_id: modelData.order.id,
      shipment_products: shipment_products,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
    };
    return data;
  };
  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Shipment created'][language]);
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className={styles.inputData}>
        <OpeningBlock
          title={interface_translate['About shipment'][language]}
          open={true}
        >
          {attrsForCreate.has('order_id') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <Autocomplete
                  value={modelData.order}
                  onChange={(e, newElement) => {
                    if (newElement == null) {
                      modelData.products = [];
                      setProducts([]);
                    } else {
                      setProductsForSelect(newElement.products);
                    }

                    setModelData({
                      ...modelData,
                      order: newElement,
                    });
                  }}
                  options={orderElementsForSelect}
                  getOptionLabel={(model) => model.id.toString()}
                  onInputChange={(e, value) => {
                    setOrderInputValue(value);
                    setOrderElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Order'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={orderElementsForSelect.length == 0}
                />
              </div>
              {errors['order_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['order_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('products') && (
            <div>
              {modelData.order !== null && modelData.order !== undefined && (
                <ProductsWithQtyInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  products={products}
                  setSelectedElement={(value) => {
                    setProducts((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setProducts((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  order={modelData.order}
                  defaultElements={productsForSelect}
                  product_ids_errors={(() => {
                    const err_data = errors['shipment_product.qty'];
                    if (!err_data) return {};
                    return {
                      [err_data.productId]: `Превышает допустимый лимит ${err_data.availableStock}`,
                    };
                  })()}
                  errorContainerClass={'openingBlockErrorAttr'}
                />
              )}
              {errors['products_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['products_ids'][0]][language]}
                </div>
              )}
              {errors['shipment_products'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['shipment_products'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('packing_translate') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Packing'][language]}
                </div>
                <select
                  value={modelData.packing}
                  onChange={(e) => {
                    setModelData({ ...modelData, packing: e.target.value });
                  }}
                >
                  {packing.map((status, id) => (
                    <option
                      key={id}
                      value={status}
                    >
                      {types[status][language]}
                    </option>
                  ))}
                </select>
              </div>
              {errors['packing'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['packing'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default ShipmentCreatePage;
