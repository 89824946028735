import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BarcodeScanner from '../../components/BarcodeScanner/BarcodeScanner.jsx';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';
import useCommon from '../../hooks/useCommon.jsx';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import ModalWindow from '../../components/ModalWindow.jsx';
import ConfirmDialog from '../../components/ConfirmDialog.jsx';
import roleAccess from '../../roleAccess.js';
import deliveryService from '../../services/delivery.js';
import userService from '../../services/user.js';
import orderService from '../../services/order.js';
import ListAttrMedia from '../../components/ListAttrMedia.jsx';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import UnidentifiedDeliveryCreatePage from '../unidentified_delivery/UnidentifiedDeliveryCreatePage.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface.js';
import styles from './DeliveriesPage.module.css';
import '../../App.css';
import { admin, supplier_realtions_manager } from '../../roles.js';

async function parsel_scan_track_number(
  track_number,
  setGlobalError,
  language
) {
  const result = await deliveryService.scan_track_number(
    track_number,
    setGlobalError,
    language
  );
  return result;
}

async function delete_delivery(id, setGlobalError, language) {
  const result = await deliveryService.delete(id, setGlobalError, language);
  return result;
}

async function get_users(filters = {}, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

async function get_orders(filters = {}, size = 50) {
  const orders_paginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orders_paginated.data;
}

async function get_delivery_statuses() {
  const statuses = await deliveryService.get_statuses();
  return statuses;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'delivery id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'datetime_created',
    name_for_display: 'datetime created',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'track_number',
    name_for_display: 'track number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'add_information',
    name_for_display: 'add information',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const DeliveriesPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation()
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['deliveries'];
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [errors, setErrors] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);

  const [viewScanTrackNumberModal, setViewScanTrackNumberModal] =
    useState(false);
  const [viewManualTrackNumberModal, setViewManualTrackNumberModal] = useState(false);
  const [manualTrackNumber, setManualTrackNumber] = useState('');
  const [scanningBarcode, setScanningBarcode] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState('');
  const [trackNumberInput, setTrackNumberInput] = useState('');
  const [
    receivedDeliveryIdAfterCheckingBarcode,
    setReceivedDeliveryIdAfterCheckingBarcode,
  ] = useState(null);
  const [viewBadScanBarcodeModal, setViewBadScanBarcodeModal] = useState(false);
  const [viewEnterBarcodeModal, setViewEnterBarcodeModal] = useState(false);
  const [viewSuccessfulGetBarcode, setViewSuccessfulGetBarcode] =
    useState(false);

  const [
    viewCreateUnidentifiedDeliveryConfirm,
    setViewCreateUnidentifiedDeliveryConfirm,
  ] = useState(false);
  const [createUnidentifiedDeliveryView, setCreateUnidentifiedDeliveryView] =
    useState(false);
    const batchUpdateDeliveries = async (deliveryIds) => {
      const errors = [];
    
      for (const id of deliveryIds) {
        try {
          const delivery = await deliveryService.get(id);
    
          const dataToUpdate = {
            status: 'product_is_checked',
            products_is_agreed: true,
            delivery_products: delivery.delivery_products.map(product => ({
              product_id: product.product?.id || product.product_id, // Явно добавляем product_id
              ...product,
              status: 'product_is_checked',
            })),
          };
    
          console.log(`Data to update for delivery ID ${id}:`, dataToUpdate); // Логируем данные для проверки
    
          await deliveryService.update(id, dataToUpdate, setErrors, setGlobalError, resetGlobalMsg, language);
        } catch (error) {
          console.error(`Ошибка при обновлении поставки с ID ${id}:`, error);
          errors.push(`Ошибка при обновлении поставки с ID ${id}`);
        }
      }
    
      if (errors.length > 0) {
        setGlobalError(`Ошибки при обновлении поставок: ${errors}`);
      } else {
        setGlobalMsg("Все выбранные поставки успешно обновлены.");
      }
    
      requestGetDeliveries();
    };
    
  useEffect(() => {
    const req = async () => {
      const deliveryStatuses = await get_delivery_statuses();
      setDeliveryStatuses(deliveryStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Deliveries.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {
    for (let delivery of deliveries) {
      delivery.status_translate = statuses_translate[delivery.status][language];
    }
    setDeliveries([...deliveries]);
  }, [language]);

  useEffect(() => {
    setAllViewSettings({
      deliveries: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'status_translate') {
      sortData.by = 'status';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const setViewScanTrackNumber = (value) => {
    setViewScanTrackNumberModal(value);
    setTrackNumberInput('');
    setScanningBarcode(value);
  };

  const scanBarcode = async (value) => {
    setBarcodeValue(value);
    const result = await parsel_scan_track_number(
      value,
      setGlobalError,
      language
    );
    if (result) {
      requestGetDeliveries();
      setViewSuccessfulGetBarcode(true);
      setReceivedDeliveryIdAfterCheckingBarcode(result.id);
    } else {
      setViewBadScanBarcodeModal(true);
    }
    setViewScanTrackNumber(false);
  };
  
  const mapFilterData = () => {
    console.log('Full filterData:', filterData);
    const data = { ...filterData };
    
    // Преобразование ID из объектов
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    if (filterData.order) 
      data['order_id'] = filterData.order.id;

    // Удаление пустых значений
    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });

    // Удаление объектов, которые уже преобразованы в ID
    delete data['order'];
    delete data['responsible'];
    delete data['co_executors'];
    
    console.log('Mapped filter data:', data);
    return data;
  };

  const requestGetDeliveries = async () => {
    const filter = mapFilterData();
    console.log('Filter data:', filter);
    const new_deliveries_paginated = await deliveryService.get_by_filters(
      filter,
      paginationSettings
    );
    const new_deliveries = new_deliveries_paginated.data;
    for (let delivery of new_deliveries) {
      delivery.status_translate = statuses_translate[delivery.status][language];
      delivery.storehouse_name = delivery.storage_cell
        ? delivery.storage_cell.shelving.storehouse.name
        : '';
      delivery.storage_cell_name = delivery.storage_cell
        ? `${delivery.storage_cell.shelving.name}${delivery.storage_cell.number}`
        : '';
      delivery.datetime_created = new Date(delivery.datetime_created).toLocaleDateString(); 
    }
    setTotalPages(new_deliveries_paginated.total);
    setDeliveries(new_deliveries);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetDeliveries();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setDeliveries(
      deliveries.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await delete_delivery(id, setGlobalError, language);
      if (!result) {
        requestGetDeliveries();
      }
    }
  };
  return (
    <div>
      <ModelsControls
        section="Deliveries"
        model_name={interface_translate['Deliveries'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetDeliveries}
        deleteModels={deleteModels}
      >
        {roleAccess[userRole].permissions.Deliveries.scan_track_number && (
          <button
            onClick={() => {
              setViewScanTrackNumber(true);
            }}
          >
            {interface_translate['Scan track number'][language]}
          </button>
        )}
        {roleAccess[userRole].permissions.Deliveries.scan_track_number && (
            <button
                onClick={() => {
                    setViewManualTrackNumberModal(true);
                }}
            >
               {interface_translate['Enter track number'][language]}
            </button>
        )}
         {['roman_assistant', 'admin'].includes(userRole) && selectedModelIds.length > 0 && (
          <button onClick={() => batchUpdateDeliveries(selectedModelIds)}>
            {interface_translate['Coordination with the client'][language]}
          </button>
        )}
      </ModelsControls>
      {showFilters && (
        <ModelFilters
          requestModels={requestGetDeliveries}
          resetFilterData={() => setFiltersData({})}
        >
          <AutocompleteFilter
            title_name={interface_translate['Order'][language]}
            attr="order"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['order'] != undefined ? filterData['order'] : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['order'] = value;
              const orders = await get_orders(filters);
              return orders;
            }}
          />
          <TextFilter
            title_name={interface_translate['Track number'][language]}
            attr="track_number"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['track_number'] != undefined
                ? filterData['track_number']
                : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...deliveryStatuses.map((m) => statuses_translate[m][language]),
            ]}
            select_attr={['', ...deliveryStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] != undefined ? filterData['status'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Total weight in kg'][language]}
            attr="total_weight_in_kg"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['total_weight_in_kg'] != undefined
                ? filterData['total_weight_in_kg']
                : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Total volume in м³'][language]}
            attr="total_volume_in_m3"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['total_volume_in_m3'] != undefined
                ? filterData['total_volume_in_m3']
                : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Actual content'][language]}
            attr="actual_content"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['actual_content'] != undefined
                ? filterData['actual_content']
                : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] != undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Co executors'][language]}
            attr="co_executors"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['co_executors'] != undefined
                ? filterData['co_executors']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const coExecutors = await get_users(filters);
              return coExecutors;
            }}
          />
        </ModelFilters>
      )}
      {viewScanTrackNumberModal && (
        <ModalWindow
          isActive={viewScanTrackNumberModal}
          setIsActive={(value) => {
            setViewScanTrackNumber(value);
          }}
        >
          <div className={styles.modalContainer}>
            {scanningBarcode && (
              <BarcodeScanner
                scanning={scanningBarcode}
                setScanning={setScanningBarcode}
                setBarcodeValue={(value) => {
                  setBarcodeValue(value);
                  scanBarcode(value);
                }}
              />
            )}
          </div>
        </ModalWindow>
      )}
      {viewManualTrackNumberModal && (
          <ModalWindow
              isActive={viewManualTrackNumberModal}
              setIsActive={(value) => {
                  setViewManualTrackNumberModal(value);
              }}
          >
              <div className={styles.modalContainer}>
                  <h3 className={styles.modalTitle}>
                      {interface_translate['Enter track number'][language]}
                  </h3>
                  <div className={styles.fieldInput}>
                      <span>{interface_translate['Track number'][language]}</span>
                      <input
                          type="text"
                          placeholder={interface_translate['Enter track number'][language]}
                          value={manualTrackNumber}
                          onChange={(e) => setManualTrackNumber(e.target.value)}
                          onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                  scanBarcode(manualTrackNumber);
                              }
                          }}
                          className={styles.inputField}
                      />
                  </div>
                  {/* <div className={styles.modalButtons}>
                      <button
                          className={styles.confirmButton}
                          onClick={() => scanBarcode(manualTrackNumber)}
                      >
                          {interface_translate['Save'][language]}
                      </button>
                      <button
                          className={styles.cancelButton}
                          onClick={() => setViewManualTrackNumberModal(false)}
                      >
                          {interface_translate['Exit'][language]}
                      </button>
                  </div> */}
              </div>
          </ModalWindow>
      )}
      {viewBadScanBarcodeModal && (
        <ModalWindow
          isActive={viewBadScanBarcodeModal}
          setIsActive={(value) => {
            setViewBadScanBarcodeModal(value);
          }}
        >
          <div className={styles.modalContainer}>
            <p>
              {interface_translate['Track number'][language]}: {barcodeValue}
            </p>
            <button
              onClick={() => {
                setViewBadScanBarcodeModal(false);
                setViewEnterBarcodeModal(true);
              }}
            >
              {interface_translate['Enter the track number'][language]}
            </button>
            <button
              onClick={() => {
                setViewBadScanBarcodeModal(false);
                setViewCreateUnidentifiedDeliveryConfirm(true);
              }}
            >
              {interface_translate['Create an unidentified delivery'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
      {viewEnterBarcodeModal && (
        <ModalWindow
          isActive={viewEnterBarcodeModal}
          setIsActive={(value) => {
            setViewEnterBarcodeModal(value);
          }}
        >
          <div className={styles.modalContainer}>
            <div className={styles.fieldInput}>
              <span>{interface_translate['Track number'][language]}</span>
              <input
                type="text"
                value={trackNumberInput}
                onChange={(e) => setTrackNumberInput(e.target.value)}
              />
            </div>
            <button
              onClick={() => {
                setBarcodeValue(trackNumberInput);
                scanBarcode(trackNumberInput);
                setViewEnterBarcodeModal(false);
              }}
            >
              {interface_translate['Encontrar'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
      {viewSuccessfulGetBarcode && (
        <ModalWindow
          isActive={viewSuccessfulGetBarcode}
          setIsActive={(value) => {
            setViewSuccessfulGetBarcode(value);
          }}
        >
          <div className={styles.modalContainer}>
            <p>
              {interface_translate['Track number'][language]}: {barcodeValue}
            </p>
            <button
              onClick={() => {
                setViewSuccessfulGetBarcode(false);
                navigate(`${receivedDeliveryIdAfterCheckingBarcode}`);
              }}
            >
              {interface_translate['Confirm'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
      {viewCreateUnidentifiedDeliveryConfirm && (
        <div className={styles.createUnidentifiedDeliveryConfirmContainer}>
          <ConfirmDialog
            message={
              interface_translate['Create an unidentified delivery'][language]
            }
            confirmMsg={interface_translate['Yes'][language]}
            cancelMsg={interface_translate['No'][language]}
            onConfirm={() => {
              setViewCreateUnidentifiedDeliveryConfirm(false);
              setCreateUnidentifiedDeliveryView(true);
            }}
            onCancel={() => setViewCreateUnidentifiedDeliveryConfirm(false)}
          />
        </div>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={deliveries}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
      {createUnidentifiedDeliveryView && (
        <ModalWindow
          isActive={createUnidentifiedDeliveryView}
          setIsActive={setCreateUnidentifiedDeliveryView}
        >
          <div className={styles.mоdalCreateUnidentifiedDelivery}>
            <UnidentifiedDeliveryCreatePage
              setGlobalError={setGlobalError}
              resetGlobalError={resetGlobalError}
              setGlobalMsg={setGlobalMsg}
              resetGlobalMsg={resetGlobalMsg}
              handleExit={() => setCreateUnidentifiedDeliveryView(false)}
              trackNumber={trackNumberInput}
            />
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default DeliveriesPage;
