import React, { useState } from 'react';
import HeaderSections from './HeaderSections';
import LanguageSwitcher from '../LanguageSwitcher';
import useCommon from '../../hooks/useCommon';
import AuthBtns from '../auth/AuthBtns';
import useAuth from '../../hooks/useAuth';
import styles from './Header.module.css';
import UserActions from './UserActions';

const Header = (props) => {
  const { isAuth } = useAuth();
  const { setLanguage } = useCommon();

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <HeaderSections />
      </nav>
      {!isAuth && <AuthBtns />}
      {isAuth && <UserActions />}
      <LanguageSwitcher setLanguage={setLanguage} />
    </header>
  );
};

export default Header;
