import React, { useState, useEffect } from 'react';
import userService from '../../services/user';
import ModelView from '../../components/models_view/ModelView';
import ModelsControls from '../../components/models_view/ModelsControls';
import ModalWindow from '../../components/ModalWindow';
import UserEditForm from "./UserEditForm";
import useCommon from '../../hooks/useCommon';
import { interface_translate } from '../../texts/interface';
import ModelFilters from '../../components/ModelFilters';
import TextFilter from '../../components/filters/TextFilter';
import useViewSettings from '../../hooks/useViewSettings';
import SelectFilter from '../../components/filters/SelectFilter';
import { 
  admin, 
  chinese_storekeeper, 
  chinese_storekeeper_manager, 
  client_realtions_manager, 
  moscow_storekeeper_manager, 
  roman_assistant, 
  supplier_realtions_manager, 
  user 
} from '../../roles';

const UsersPage = () => {
  const { language } = useCommon();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['users'] || {};
  
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterData, setFilterData] = useState(viewSettings['filterData'] || {});
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'] || 50
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    size: viewSettings['size'] || 50,
    sort_by: null,
    sort_order: "DESC"
  });

  const rolesList = [
    admin,
    chinese_storekeeper,
    chinese_storekeeper_manager,
    client_realtions_manager,
    moscow_storekeeper_manager,
    roman_assistant,
    supplier_realtions_manager,
    user
  ];

  const getTranslation = (key, defaultValue) => {
    return interface_translate?.[key]?.[language] || defaultValue;
  };

  const formatFullName = (user) => {
    const parts = [user.surname, user.name, user.patronymic];
    return parts.filter(Boolean).join(' ');
  };

  const mapFilterData = () => {
    const data = { ...filterData };
    const cleanedData = {};
    
    Object.entries(data).forEach(([key, value]) => {
      if (value !== '' && value !== undefined && value !== null) {
        if (key === 'role') {
          cleanedData['roles'] = [value];
        } else {
          cleanedData[key] = value;
        }
      }
    });
    
    return cleanedData;
  };

  const requestGetUsers = async () => {
    const mappedFilters = mapFilterData();
    try {
      const data = await userService.get_by_filters(mappedFilters, {
        page: filters.page,
        size: filters.size,
        sort_by: filters.sort_by,
        sort_order: filters.sort_order
      });
      const usersWithFullName = Array.isArray(data.data) 
        ? data.data.map(user => ({
            ...user,
            full_name: formatFullName(user)
          }))
        : [];
      setUsers(usersWithFullName);
      setTotalPages(Math.ceil(data.total / filters.size));
      setError(null);
    } catch (err) {
      setUsers([]);
      setError("Failed to load users");
    }
  };

  useEffect(() => {
    requestGetUsers();
  }, [filters, filterData]); 

  useEffect(() => {
    setFilters(prev => ({
      ...prev,
      page: paginationSettings.page,
      size: paginationSettings.size
    }));
  
    setAllViewSettings({
      ...allViewSettings,
      users: {
        ...viewSettings,
        size: paginationSettings.size
      }
    });
  }, [paginationSettings]); 

  useEffect(() => {
    setAllViewSettings({
      ...allViewSettings,
      users: { 
        size: paginationSettings.size, 
        filterData: filterData 
      }
    });
  }, [paginationSettings, filterData]);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalActive(true);
  };

  const handleApplyFilters = (newFilterData) => {
    setFilterData(newFilterData);
    setPaginationSettings(prev => ({
      ...prev,
      page: 1
    }));
    setCurrentPage(1);
  };

  const resetFilterData = () => {
    setFilterData({});
    setPaginationSettings(prev => ({
      ...prev,
      page: 1
    }));
    setCurrentPage(1);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFilterData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFilterData({ ...filterData });
    }
  };

  return (
    <div>
    
      <ModelsControls
        model_name={getTranslation('Employees', 'Employees')}
        filtersActivated={showFilters}
        toggleShowFilters={() => setShowFilters(!showFilters)}
        requestModels={requestGetUsers}
        section="Users"
      />
      {showFilters && (
          <ModelFilters
            requestModels={requestGetUsers}
            resetFilterData={resetFilterData}
          >
            <TextFilter
              attr="email"
              title_name={interface_translate['Email'][language]}
              addFiltersData={addFiltersData}
              defaultValue={filterData['email'] || ''}
            />
            <TextFilter
              attr="full_name"
              title_name={interface_translate['Full Name'][language]}
              addFiltersData={addFiltersData}
              defaultValue={filterData['full_name'] || ''}
            />
            <SelectFilter
              attr="role"
              title_name={interface_translate['Role'][language]}
              select_names={['', ...rolesList]}
              select_attr={['', ...rolesList]}
              addFiltersData={addFiltersData}
              defaultValue={filterData['role'] || ''}
            />
          </ModelFilters>
        )}
      <ModelView
        items={users || []}
        models={users || []}
        onEdit={handleEdit}
        handleModelClick={(user) => {
          handleEdit(user);
        }}
        tableAttrs={[
          { 
            name: "id",
            name_for_display: "id", 
            use_sort: true 
          },
          { 
            name: "email",
            name_for_display: "Email", 
            use_sort: true 
          },
          { 
            name: "full_name",
            name_for_display: 'Full Name',
            use_sort: true 
          },
          { 
            name: "role",
            name_for_display: 'Role',
            use_sort: true 
          }
        ]}
        paginationSettings={paginationSettings}
        setPaginationSettings={setPaginationSettings}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        onPageChange={(newPage) => {
          setCurrentPage(newPage);
          setPaginationSettings(prev => ({
            ...prev,
            page: newPage
          }));
        }}
        onPageSizeChange={(newSize) => {
          setPaginationSettings(prev => ({
            ...prev,
            size: newSize,
            page: 1
          }));
          setCurrentPage(1);
        }}
      />
      <ModalWindow isActive={isModalActive} setIsActive={setIsModalActive}>
        {selectedUser && (
          <UserEditForm
            user={selectedUser}
            onClose={() => setIsModalActive(false)}
            requestGetUsers={requestGetUsers}
          />
        )}
      </ModalWindow>
    </div>
  );
};

export default UsersPage;