import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import counterpartyService from '../../services/counterparty';
import userService from '../../services/user';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './UserCreatePage.module.css';
import '../shared.css';
import {
  admin,
  client_realtions_manager,
  supplier_realtions_manager,
  roman_assistant,
} from '../../roles';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';
import * as roles from '../../roles';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await userService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

const UserCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({role: admin});
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [defUsers, setDefUsers] = useState(null);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const users = await get_users({});
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Users?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  const getDataFromAttr = () => {

    const fullNameParts = modelData.full_name.trim().split(' ');
    
    if (fullNameParts.length !== 3) {
      setGlobalError(
        interface_translate?.['Full name must include surname, name, and patronymic']?.[language] ||
        'Full name must include surname, name, and patronymic'
      );
      return;
    }
    
    const data = {
      ...modelData,
    };
    return data;
  };
  
  return (
    <div className="pageCreateModel">
      <div>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Employee added successfully'][language]
              );
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
        <OpeningBlock
          title={interface_translate['Users'][language]}
          open={true}
        >
          {attrsForCreate.has('email') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Email'][language]}
                </div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  required
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['email'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['email'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('password') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Password'][language]}
                </div>
                <input
                  type="text"
                  id="password"
                  name="password"
                  required
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      raw_password: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['password'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['password'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('full_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Full Name'][language]}
                </div>
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  required
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      full_name: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['full_name'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['full_name'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('phone_number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Phone Number'][language]}
                </div>
                <PhoneInput
                  countryCodeEditable={false}
                  country={'ru'}
                  onlyCountries={['ru', 'cn']}
                  value={modelData.phone_number}
                  onChange={(v) => {
                    setModelData({ ...modelData, phone_number: `+${v}` });
                  }}
                />
              </div>
              {errors['phone_number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['phone_number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('messenger_link') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Link to messenger'][language]}
                </div>
                <input
                  type="text"
                  id="messenger_link"
                  name="messenger_link"
                  required
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      link_to_messenger: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['link_to_messenger'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['link_to_messenger'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('bitrix_id') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Bitrix ID'][language]}
                </div>
                <input
                  type="text"
                  id="bitrix_id"
                  name="bitrix_id"
                  required
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      bitrix_id: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['bitrix_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['bitrix_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('role') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Role'][language]}
                </div>
                {
                  <select
                    onChange={(e) => {
                      console.log(e.target.value)
                      setModelData({ ...modelData, role: e.target.value });
                    }}
                  >
                    {Object.entries(roles).map(([key, value]) => (
                      <option key={key} value={value}>
                        {interface_translate[key][language]}
                      </option>
                    ))}
                  </select>
                }
              </div>
              {errors['role'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['role'][0]][language]}
                </div>
              )}
            </div>
          )}
          
        </OpeningBlock>
      
    </div>
  );
};

export default UserCreatePage;
